import React from 'react';
import { Link, graphql } from 'gatsby';
import readingTime from 'reading-time';
import Img from 'gatsby-image';
import Header from '../components/header';
import Footer from '../components/Footer';
import SEO from '../components/seo';
import Hero from '../components/Hero';
import Mailchimp from '../components/Mailchimp';
import ArrowLeft from '../images/icon_arrow_left.svg';
import twitter from '../images/icon_twitter-black.svg';
import linkedin from '../images/linkedin-black.svg';
import facebook from '../images/fb-black.svg';

import '../style/app.scss';

import { AvatarImage } from '../hooks/avatarImage';

const single = ({ data, pageContext }) => {
	const previousPost = pageContext.previous;
	const nextPost = pageContext.next;
	const stats = readingTime(data.wordpressPost.content);
	const avatar = AvatarImage();
	const image = data.wordpressPost.acf.hero_image.localFile.childImageSharp.fixed;
	let titleChange = data.wordpressPost.title;
	titleChange = titleChange.replace('&#038;', '&');

	return (
		<>
			<SEO title={titleChange} description={data.wordpressPost.excerpt} metaImage={image.src} />
			<Header />
			<Hero
				title={data.wordpressPost.title}
				excerpt={data.wordpressPost.excerpt}
				categories={data.wordpressPost.categories}
				heroImage={data.wordpressPost.acf.hero_image.localFile.childImageSharp.fluid}
			/>
			<section className="single_content">
				<div className="whole_wrapper">
					<div className="single_container">
						<div className="post_info_grid">
							<div className="avatar">
								<Img
									fixed={avatar[0].node.options.avatar_image.localFile.childImageSharp.fixed}
									alt="Aavatar"
									className="avatar_image"
								/>
							</div>
							<div className="whole_info_wrapper">
								<div className="info_wrapper">
									<span dangerouslySetInnerHTML={{ __html: stats.text }} />
									<p>
										by Andreja Mahović /{' '}
										<span className="date">
											{new Date(data.wordpressPost.date).getDate()}{' '}
											{new Date(data.wordpressPost.date).getMonth() + 1}{' '}
											{new Date(data.wordpressPost.date).getFullYear()}{' '}
										</span>
									</p>
								</div>
								<div className="share_icons">
									<span>Share:</span>

									<ul>
										<li className="facebook">
											{' '}
											<a
												href={`https://www.facebook.com/sharer/sharer.php?u=https://andrejamahovic.com/${data.wordpressPost.slug}`}
												target="_blank"
												rel="noreferrer"
											>
												<img src={facebook} alt="facebook" />
											</a>
										</li>
										<li className="twitter">
											<a
												href={`https://twitter.com/intent/tweet?url=https://andrejamahovic.com/${data.wordpressPost.slug}&text=${data.wordpressPost.title}&via=https://andrejamahovic.com`}
												target="_blank"
												rel="noreferrer"
											>
												<img src={twitter} alt="twitter" />
											</a>
										</li>
										<li className="linkedin">
											<a
												href={`https://www.linkedin.com/shareArticle?mini=true&url=https://andrejamahovic.com/${data.wordpressPost.slug}`}
												target="_blank"
												rel="noreferrer"
											>
												<img src={linkedin} alt="linkedin" />
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<span className="separator"></span>
						<div className="text_content">
							<div
								className="content_wrap"
								dangerouslySetInnerHTML={{ __html: data.wordpressPost.content }}
							/>
						</div>
						<span className="separator"></span>
						<div className="blog_ending">
							<p
								dangerouslySetInnerHTML={{
									__html: avatar[0].node.options.post_bottom_text,
								}}
							/>
							<Mailchimp />
						</div>
						<div className="pagination_wrapper">
							{previousPost ? (
								<Link to={`/${previousPost.slug}`}>
									<div className="previous">
										<Img
											fluid={previousPost.featured_media.localFile.childImageSharp.fluid}
											className="prevPostImage"
										/>
										<div className="content_wrapper">
											<h3>
												<span
													dangerouslySetInnerHTML={{
														__html: previousPost.title,
													}}
												/>
											</h3>
											<div
												dangerouslySetInnerHTML={{
													__html: previousPost.excerpt,
												}}
											/>
										</div>
										<div className="img_wrapper">
											<img src={ArrowLeft} alt="arrow left" />
										</div>
									</div>
								</Link>
							) : (
								<div></div>
							)}
							{nextPost ? (
								<Link to={`/${nextPost.slug}`}>
									<div className="next">
										<Img
											fluid={nextPost.featured_media.localFile.childImageSharp.fluid}
											className="nextPostImage"
										/>
										<div className="content_wrapper">
											<h3>
												<span dangerouslySetInnerHTML={{ __html: nextPost.title }} />{' '}
											</h3>

											<div dangerouslySetInnerHTML={{ __html: nextPost.excerpt }} />
										</div>
										<div className="img_wrapper">
											<img src={ArrowLeft} alt="arrow left" />
										</div>
									</div>
								</Link>
							) : (
								<div></div>
							)}
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
};

export default single;

export const pageQuery = graphql`
	query($id: String!) {
		wordpressPost(id: { eq: $id }) {
			categories {
				name
				slug
			}
			date
			excerpt
			slug
			content
			title
			acf {
				hero_image {
					localFile {
						childImageSharp {
							fluid {
								...GatsbyImageSharpFluid
							}
							fixed {
								...GatsbyImageSharpFixed
							}
						}
					}
				}
			}
		}
	}
`;
