import { useStaticQuery, graphql } from 'gatsby';

export const AvatarImage = () => {
	const siteAvatar = useStaticQuery(
		graphql`
			query MyQuery {
				allWordpressAcfOptions {
					edges {
						node {
							options {
								post_bottom_text
								avatar_image {
									localFile {
										childImageSharp {
											fluid {
												...GatsbyImageSharpFluid
											}
											fixed {
												...GatsbyImageSharpFixed
											}
										}
									}
								}
							}
						}
					}
				}
			}
		`
	);
	return siteAvatar.allWordpressAcfOptions.edges;
};
