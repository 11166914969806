import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import ArrowTop from '../images/arrow_top_new.png';

const Hero = content => (
	<section className="single_hero">
		<div className="back_button_wrapper">
			<Link to="/">
				<span>
					<img src={ArrowTop} alt="back arrow" /> Back to all posts
				</span>
			</Link>
		</div>
		<Img className="single_hero_image" fluid={content.heroImage} alt="Hero Post Image" />
		<div className="main_heading">
			<h1 dangerouslySetInnerHTML={{ __html: content.title }} />
			<div dangerouslySetInnerHTML={{ __html: content.excerpt }} />
		</div>
		<div className="whole_wrapper">
			<div className="tags_wrapper">
				<div className="post_tags_single">
					{content.categories.map(tag => (
						<Link key={tag.id} to={`/category/${tag.slug}`}>{`#${tag.name}`}</Link>
					))}
				</div>
			</div>
		</div>
	</section>
);

export default Hero;
