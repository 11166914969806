import React from 'react';

import addToMailchimp from 'gatsby-plugin-mailchimp';
import { useFormik } from 'formik';

const MailChimpForm = () => {
	const formik = useFormik({
		initialValues: {
			email: '',
			firstName: '',
		},
		onSubmit: values => {
			addToMailchimp(values.email, { FNAME: values.firstName }).then(data => {
				if (data.result === 'error') {
					alert('error: likely a duplicate email');
				} else {
					alert('Thank you for subscribing');
				}
			});
		},
	});
	return (
		<div className="form_wrapper">
			<form onSubmit={formik.handleSubmit}>
				<label htmlFor="email">
					<input
						id="email"
						name="email"
						type="email"
						placeholder="Email"
						aria-label="email"
						onChange={formik.handleChange}
						value={formik.values.email}
					/>
				</label>
				<button type="submit">Subscribe</button>
			</form>
		</div>
	);
};

export default MailChimpForm;
